// Override default variables before the import
// $body-bg: rgb(72, 94, 168);
$enable-gradients: true;
$body-bg:white;
// $primary: #5a87cb;
$nav-colors: #3366cc;
$primary: #3366cc;
// $body-bg: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(194,210,246,1) 0%, rgba(79,126,196,1) 100%);
// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url(//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css);

.color-nav{
    background-color: $nav-colors;
}
.nav-link{
  color: white !important;
}
.external-link {
  color: $primary;
  cursor: pointer;
}
.nav-btn {
    color: white;
}

.home {
    align-content: center;
}
body {
    font-family: 'Roboto', sans-serif;
}

.myCarousel {
    height: auto;
    width: auto;
    overflow: hidden;
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 100px;
  width: 100px;
  outline: black;
  background-size: 100%, 100%;

  background-image: none;
}

.carousel-control-next-icon:after
{
  content: '❯';
  font-size: 60px;
  color: black;
}

.carousel-control-prev-icon:after {
  content: '❮';
  font-size: 60px;
  color: black;
}

.column {
    float: left;
    width: 33.33%;
    padding: 5px;
  }
  
  /* Clear floats after image containers */
  .row::after {
    content: "";
    clear: both;
    display: table;
  }
// Footer styles from https://www.ordinarycoders.com/blog/article/bootstrap-footers
  .footer-dark {
    padding:25px 0;
    color:#f0f9ff;
    background-color: $nav-colors;
  }

  .footer-dark h3 {
    margin-top:0;
    margin-bottom:12px;
    font-weight:bold;
    font-size:18px;
  }
  
  .footer-dark ul {
    padding:0;
    list-style:none;
    line-height:1.6;
    font-size:16px;
    margin-bottom:0;
  }
  
  .footer-dark ul a {
    color:white;
    text-decoration:none;
    opacity:0.7;
  }
  
  .footer-dark ul a:hover {
    opacity:0.9;
  }
  
  .footer-dark .item.text {
    margin-bottom:12px;
  }
  
  @media (max-width:767px) {
    .footer-dark .item.text {
      margin-bottom:0;
    }
  }
  
  .footer-dark .item.text p {
    // opacity:0.8;
    margin-bottom:0;
  }

  .footer-dark .item.social {
    text-align:center;
  }
  
  @media (max-width:991px) {
    .footer-dark .item.social {
      text-align:center;
      margin-top:20px;
    }
  }
  
  .footer-dark .item.social > a {
    font-size:20px;
    width:36px;
    height:36px;
    line-height:36px;
    display:inline-block;
    text-align:center;
    border-radius:50%;
    box-shadow:0 0 0 1px rgba(255,255,255,0.4);
    margin:0 8px;
    color:#fff;
    opacity:0.9;
  }
  
  .footer-dark .item.social > a:hover {
    cursor: pointer;
    // opacity:0.9;
  }
  
  .footer-dark .copyright {
    text-align:center;
    padding-top:24px;
    opacity:0.5;
    font-size:14px;
    margin-bottom:0;
  }
  // End Footer Styles

  .border-frame {
    border-style: solid;
    border-width: 1em;
    border-color: $primary;
  }
  // Contact form styles
  // https://codepen.io/formbucket/pen/xEKYoX
  .form-box {
    max-width: 500px;
    margin: auto;
    padding: 50px;
    background: #ffffff;
    border: 10px solid #f2f2f2;
  }
  
  h1, p {
    text-align: center;
  }
  
  input, textarea {
    width: 100%;
  }
  .form-group.required .control-label:after {
    content:"*";
    color:red;
  }

  // Cards

  // .program-card{
  //   background-color: #5a87cb;
  // }

/* Flip Cards CSS */
// https://codepen.io/nicolaskadis/pen/brQEOd
.card-container {
  perspective: 700px;
}
.card-flip {
  position: relative;
  width: 100%;
  transform-style: preserve-3d;
  height: auto;
  transition: all 0.5s ease-out;
}

.card-flip div {
  backface-visibility: hidden;
  transform-style: preserve-3d;
  height: 100%;
  width: 100%;
}

.card-flip .front {
  position:absolute;
  z-index: 1;
}

.card-flip .back {
  position: relative;
  text-overflow: ellipsis;
  z-index: 0;
  transform: rotateY(-180deg);
}

.card-container:hover .card-flip {
  transform: rotateY(180deg);
}
// end card flip

.hr-programs {
  border: 2px solid $primary;
  margin: 1px;
}

.card-topic {
  height: 150px;
  width: 150px;
  background-color: $primary;
  color: white;
}
.arrow-bullet {
  font-size: 40px;
  color: #ffe599;
  max-width: 50px;
}

input.invalid {
  border-color: red;
}

input {
  max-width: 400px;
}

.impact-card {
  background-color: rgb(255, 241, 176);
}

// Star Ratings

.star-rating > input {display: none;}  /* Remove radio buttons */

.star-rating > label:before { 
  content: "\f005"; /* Star */
  margin: 2px;
  font-size: 4em;
  font-family: FontAwesome;
  display: inline-block; 
}

.star-rating > input:checked ~ label{ 
  color: #ffca08; 
}

.half-star-rating > input {display: none;}  /* Remove radio buttons */

.half-star-rating > label:before { 
  content: "\f123"; /* Half Star */
  margin: 2px;
  font-size: 4em;
  font-family: FontAwesome;
  display: inline-block; 
}

.half-star-rating > input:checked ~ label{ 
  color: #ffca08; 
}
.empty-star-rating > input {display: none;}  /* Remove radio buttons */

.empty-star-rating > label:before { 
  content: "\f006"; /* Empty Star */
  margin: 2px;
  font-size: 4em;
  font-family: FontAwesome;
  display: inline-block; 
}

.empty-star-rating > input:checked ~ label{ 
  color: #ffca08; 
}

.tab-link {
  color: $primary;
}

.tab-link:hover {
  text-decoration: underline;
}